@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    height: 100%;
    background-color: #282c34;
}

.colordef {
    background-color: #282c34;
}

.fullHeight {
    min-height: 100vh; /* Take up at least the full height of the viewport */
    display: flex;
    flex-direction: column;
}

/* Chat Interface Styling */

/* General styles for all chat messages */
.chat-message {
    padding: 10px 15px;
    margin: 5px 0;
    border-radius: 10px;
    max-width: 70%; /* Limit message width for better aesthetics */
    word-wrap: break-word; /* Wrap long messages */
}

/* Styling for user messages */
.user-message {
    background-color: #dcf8c6; /* Light green background for user messages */
    align-self: flex-end; /* Align to the right */
    margin-right: 10px; /* Some margin for aesthetics */
    border-top-right-radius: 0; /* Make top right corner flat */
    color: #282c34;
}

/* Styling for bot messages */
.bot-message {
    background-color: #eaeaea; /* Light gray background for bot messages */
    align-self: flex-start; /* Align to the left */
    margin-left: 10px; /* Some margin for aesthetics */
    border-top-left-radius: 0; /* Make top left corner flat */
    color: #282c34;
}

/* Container for chat history to enable flex layout */
.chat-history {
    margin: auto;
    width: 80%;
    display: flex;
    flex-direction: column; /* Stack messages vertically */
    align-items: flex-start; /* Start with alignment to the left (for bot messages) */
}

/* Set the background color for the entire app */
.App {
    text-align: center;
    background-color: #282c34; /* This is the default background color in the App.css provided earlier */
}

/* Set the background color for the chat history container */
.chat-history {
    background-color: #282c34;
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

@media (min-width: 768px) {
    .Profile-img {
        object-fit: cover;
        width: 200px;
        height: 200px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 100px;
    }
    .greeting {
        padding: 10px 10px 20px 10px;
        font-size: 14px;
    }
    .bot-name {
        font-weight: bold;
    }
    .chat-input {
        position: absolute;
        bottom: 10px; /* position it 10 pixels from the bottom */
        left: 10%; /* centering it horizontally */
        right: 10%; /* centering it horizontally */
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    .cardd {
        background-color: #61dafb;
        border-radius: 5em;
    }
}

@media (max-width: 767px) {
    body {
        font-size: 14px;
    }

    .Profile-img {
        object-fit: cover;
        width: 130px;
        height: 130px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 100px;
    }

    .projects-grid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    /* App.css or another relevant CSS file */

    .note-card {
        width: 300px; /* Adjust based on your design preference */
        height: 200px; /* Adjust based on your design preference */
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px; /* Space between cards */
        cursor: pointer; /* Indicates the element is clickable */
        background-color: #333; /* Example color */
        color: white; /* Example text color */
        padding: 15px;
        border-radius: 5px; /* Optional: for rounded corners */
    }

    .note-content {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Number of lines you want displayed */
        -webkit-box-orient: vertical;
    }

    .expanded {
        height: auto;
    }
}
